<template>
  <div>
    <div v-if="items.length">
      <v-data-table-server
        :headers="dairyFields"
        :items="items"
        :fields="dairyFields"
        hide-default-footer
        disable-pagination
        :items-length="items.length"
        class="min-width custom-table-styling >"
      >
        <!-- Differenz zu TSM1 -->
        <template v-slot:[`item.differenceSellingQuantity`]="{ item }">
          <div :class="getClass(item)">
            <span class="dbm-number">
              {{ $formatNumber(calculateDifferenceSellingQuantity(item)) }}
            </span>
          </div>
        </template>
      </v-data-table-server>
    </div>
  </div>
</template>

<script lang="ts">
import { DTSHeaders } from '@/services/BackendService'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SegmentationFormValidationSegmentationTable',
  props: {
    items: { type: Array, required: true },
    groupDTO: { Object, required: true }
  },
  data() {
    return {
      dairyFields: [
        // Pos-Nr.
        {
          title: this.$t('segmentation_forms_preview_id'),
          key: 'posNo',
          sortable: false,
          width: '5%'
        },
        // Segment
        {
          title: this.$t('segmentation_forms_preview_segmentation_type'),
          key: 'segmentationType' + this.$getUpLangKey(),
          sortable: false,
          width: '10%'
        },
        // Fütterung
        {
          title: this.$t('segmentation_forms_preview_feeding'),
          key: 'feeding' + this.$getUpLangKey(),
          sortable: false,
          width: '10%'
        },
        // MBH-ID
        {
          title: this.$t('segmentation_forms_preview_mbhident'),
          key: 'dairy.ident',
          sortable: false,
          width: '5%',
          align: 'end'
        },
        // Name/Firma
        {
          title: this.$t('segmentation_forms_preview_name_company'),
          key: 'dairy.addressName1',
          sortable: false,
          width: '10%'
        },
        // Vorname/Zusatz
        {
          title: this.$t('segmentation_forms_preview_addition'),
          key: 'dairy.addressName2',
          sortable: false,
          width: '10%'
        },
        // Strasse
        {
          title: this.$t('segmentation_forms_preview_street'),
          key: 'dairy.addressLine1',
          sortable: false,
          width: '10%'
        },
        // PLZ
        {
          title: this.$t('segmentation_forms_preview_zip'),
          key: 'dairy.addressZip',
          sortable: false,
          width: '5%'
        },
        // Ort
        {
          title: this.$t('segmentation_forms_preview_city'),
          key: 'dairy.addressLocality',
          sortable: false,
          width: '10%'
        },
        // Menge in kg
        {
          title: this.$t('segmentation_forms_preview_quantity'),
          key: 'quantity',
          sortable: false,
          width: '10%',
          align: 'end'
        },
        // Differenz zu TSM 1
        {
          title: this.$t('segmentation_forms_preview_difference_business_partner'),
          key: 'differenceSellingQuantity',
          sortable: false,
          width: '5%',
          align: 'end'
        },
        // Status von TSM 1
        {
          title: this.$t('segmentation_forms_preview_status_business_partner'),
          key: 'counterpartStatus' + this.$getUpLangKey(),
          sortable: false,
          width: '10%'
        }
      ] as DTSHeaders
    }
  },
  methods: {
    calculateDifferenceSellingQuantity(item) {
      if (item.counterpartQuantity == null && item.quantity == null) return ''
      if (item.quantity == null) return 0 - item.counterpartQuantity
      if (item.counterpartQuantity == null) return item.quantity - 0
      return item.quantity - item.counterpartQuantity
    },
    getClass(item) {
      if (this.groupDTO.validationGroupType == 'DIFFERENCE' && Math.abs(this.calculateDifferenceSellingQuantity(item)) > 50) {
        return 'red-difference col-min-width'
      }

      return 'col-min-width'
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.red-difference {
  color: $dbm-red !important;
}

.custom-table-styling > .v-table__wrapper .v-input__slot > .v-text-field__slot {
  background: #f1dcb0;
}

.custom-table-styling > .v-table__wrapper .v-input--is-readonly .v-input__slot > .v-text-field__slot {
  background: #f1f1f1 !important;
}
.custom-table-styling > .v-table__wrapper .v-input--is-disabled .v-input__slot > .v-text-field__slot,
.custom-table-styling > .v-table__wrapper .v-input--is-disabled .v-select__slot {
  background: #ffffff !important;
}
</style>
