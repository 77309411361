<template>
  <div class="container">
    <h3 id="edit_step_one_title">
      <span v-html="$t('segmentation_forms_control_title')" /> -
      <span v-html="$t('period')" />
      {{ $formatChDate(segmentationForm.validFrom) }}&nbsp;-&nbsp;{{ $formatChDate(segmentationForm.validUntil) }}
    </h3>
    <v-row>
      <div v-html="$t('segmentation_forms_validation_info')" />
      <v-col>
        <div class="mb-4">
          <h1 v-html="$t('segmentation_validation_overview')"></h1>
          <v-row density="compact">
            <template v-if="segmentationFormValidationGroups.okCount > 0">
              <v-col cols="2" class="pt-3"><span v-html="$t('segmentation_validation_ok')"></span></v-col>
              <v-col cols="10">
                <v-chip variant="flat" :color="colors.OK" class="chip-bright ma-2">{{ segmentationFormValidationGroups.okCount }} </v-chip>
              </v-col>
            </template>
            <template v-if="segmentationFormValidationGroups.warningCount > 0">
              <v-col cols="2" class="pt-3"><span v-html="$t('segmentation_validation_warning')"></span></v-col>
              <v-col cols="10">
                <v-chip variant="flat" :color="colors.WARNING" class="chip-bright ma-2">
                  {{ segmentationFormValidationGroups.warningCount }}
                </v-chip>
              </v-col>
            </template>
            <template v-if="segmentationFormValidationGroups.errorCount > 0">
              <v-col cols="2" class="pt-3"><span v-html="$t('segmentation_validation_error')"></span></v-col>
              <v-col class="10">
                <v-chip variant="flat" :color="colors.ERROR" class="chip-bright ma-2">
                  {{ segmentationFormValidationGroups.errorCount }}
                </v-chip>
              </v-col>
            </template>
          </v-row>
        </div>

        <v-expansion-panels v-model="panels" multiple>
          <v-expansion-panel v-for="groupDTO of segmentationFormValidationGroups.groupDTOs" v-bind:key="'groupDTO' + groupDTO.validationGroupType">
            <v-expansion-panel-title>
              <h1>
                {{ groupDTO.translatedName[this.$getLangKey()] }}
              </h1>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="subGroup of groupDTO.subGroups"
                  v-bind:key="'groupDTO_' + groupDTO.validationGroupType + '_subGroup_' + subGroup.delivery + subGroup.result"
                >
                  <v-expansion-panel-title>
                    <v-icon style="max-width: 2rem" :color="getColor(subGroup)">
                      {{ getIcon(subGroup) }}
                    </v-icon>
                    <span v-html="$t('segmentation_validation_' + groupDTO.validationGroupType + '_' + subGroup.delivery + '_' + subGroup.result)" />
                  </v-expansion-panel-title>
                  <v-expansion-panel-text v-if="!subGroup.warning && (subGroup.segmentationRows.length || subGroup.processingRows.length)">
                    <div v-if="groupDTO.validationGroupType === 'DIFFERENCE_PROCESSING'">
                      <SegmentationFormValidationProcessingTable :items="subGroup.processingRows" :groupDTO="groupDTO" :subGroup="subGroup" />
                    </div>
                    <div v-else>
                      <SegmentationFormValidationSegmentationTable :items="subGroup.segmentationRows" :groupDTO="groupDTO" :subGroup="subGroup" />
                    </div>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <div>
        <v-btn color="secondary" class="shadow-none" @click="goBack()"><span v-html="$t('back')" /></v-btn>
        <v-btn color="secondary" class="shadow-none" @click="cancel()"><span v-html="$t('cancel')" /></v-btn>
        <v-btn color="primary" class="shadow-none" @click="goForward()"><span v-html="$t('continue')" /></v-btn>
      </div>
    </v-row>
  </div>
</template>

<script lang="ts">
import { apiURL } from '@/main'
import { showError } from '@/services/axios'
import SegmentationFormValidationProcessingTable from '@/views/segmentationForms/segmentationFormValidationProcessingTable.vue'
import SegmentationFormValidationSegmentationTable from '@/views/segmentationForms/segmentationFormValidationSegmentationTable.vue'
import { segmentationFormMinimal } from './edit.vue'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'segmentationForms_edit_step_one',
  data() {
    return {
      panels: [],
      segmentationFormValidationGroups: [],
      colors: {
        OK: 'green',
        WARNING: 'yellow darken-3',
        ERROR: 'red'
      },
      icons: {
        OK: 'mdi-checkbox-marked-circle',
        WARNING: 'mdi-alert',
        ERROR: 'mdi-cancel'
      },
      segmentationForm: segmentationFormMinimal
    }
  },
  methods: {
    async loadSegmentationFormHeader() {
      try {
        const result = await this.axios.get(apiURL + '/segmentationForms/' + this.$route.params.id, { headers: { 'Content-Type': 'application/json' } })
        if (await result) {
          this.segmentationForm = result.data
          return result.data
        }
      } catch (e) {
        showError(e)
      }
    },
    getColor(subGroup) {
      if (subGroup.error) return this.colors.ERROR
      if (subGroup.warning) return this.colors.WARNING
      return this.colors.OK
    },
    getIcon(subGroup) {
      if (subGroup.error) return this.icons.ERROR
      if (subGroup.warning) return this.icons.WARNING
      return this.icons.OK
    },
    async getSegmentationFormValidation() {
      try {
        const result = await this.axios.get(
          apiURL + '/segmentationForms/' + this.$route.params.id + '/validation',
          {
            params: {
              segmentationFormId: this.$route.params.id
            }
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          return result.data
        }
      } catch (e) {
        showError(e)
      }
    },
    setOpenPanels(data) {
      let result = []
      data.groupDTOs.forEach((element, index) => {
        if (element.errorCount > 0 || element.warningCount > 0) {
          result.push(index)
        }
      })
      this.panels = result
    },
    async goForward() {
      this.$router
        .push({
          name: 'segmentationForms_edit_step_two',
          params: { id: this.$route.params.id }
        })
        .catch((err) => {
          // duplicated route
        })
    },
    goBack() {
      this.$router
        .push({
          name: 'segmentationForms_edit'
        })
        .catch((err) => {
          // duplicated route
        })
    },
    cancel() {
      this.$router
        .push({
          name: 'segmentationForms_read'
        })
        .catch((err) => {
          // duplicated route
        })
    }
  },
  components: {
    SegmentationFormValidationProcessingTable: SegmentationFormValidationProcessingTable,
    SegmentationFormValidationSegmentationTable: SegmentationFormValidationSegmentationTable
  },
  mounted: function () {
    this.$nextTick(async () => {
      this.segmentationFormValidationGroups = await this.getSegmentationFormValidation()
      this.setOpenPanels(await this.segmentationFormValidationGroups)
      this.loadSegmentationFormHeader()
    })
  }
})
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.bottom-margin {
  margin-bottom: $spacer-xxs;
}
</style>
