<template>
  <div>
    <div>
      <!-- Milcheinkauf direkt von Produzenten -->
      <div class="v-data-table min-width theme--light custom-table-styling >">
        <div class="v-table__wrapper">
          <table class="table table-sm">
            <thead>
              <tr>
                <th :width="farmFields[0].width">
                  <span v-html="$t('step_four_processing_form')" />
                </th>
                <th :width="farmFields[1].width">
                  <span v-html="$t('segmentation_forms_preview_feeding')" />
                </th>
                <th :width="farmFields[2].width">
                  <span />
                </th>
                <th :width="farmFields[3].width">
                  <span />
                </th>
                <th :width="farmFields[4].width">
                  <span />
                </th>
                <th :width="farmFields[5].width">
                  <span />
                </th>
                <th :width="farmFields[6].width">
                  <span />
                </th>
                <th :width="farmFields[7].width">
                  <span />
                </th>
                <th :width="farmFields[8].width" align="right">
                  <span v-html="$t('segmentation_forms_preview_quantity')" />
                </th>
                <th :width="farmFields[9].width" align="right">
                  <span v-html="$t('segmentation_forms_preview_difference_tsm_1')" />
                </th>
                <th :width="farmFields[10].width">
                  <span v-html="$t('segmentation_forms_preview_status_tsm_1')" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="pfItem of items" :key="pfItem.code">
                <!-- CCode -->
                <td>
                  {{ pfItem.code }}
                </td>
                <!-- Fütterung -->
                <td>
                  {{ pfItem['feeding' + this.$getUpLangKey()] }}
                </td>
                <!-- Ident Placeholder -->
                <td></td>
                <!-- AddressName1 Placeholder -->
                <td></td>
                <!-- addressName2 Placeholder -->
                <td></td>
                <!-- addressLine1 Placeholder -->
                <td></td>
                <!-- addressZip Placeholder -->
                <td></td>
                <!-- addressLocality Placeholder -->
                <td></td>
                <!-- Menge kg -->
                <td align="right">
                  <span class="dbm-number">
                    {{ $formatNumber(pfItem.sumQuantityABC) }}
                  </span>
                </td>
                <!-- Differenz zu TSM1 -->
                <td align="right">
                  <div :class="getClass(pfItem)">
                    <span class="dbm-number">
                      {{ $formatNumber(pfItem.sumQuantityABC - pfItem.quantity) }}
                    </span>
                  </div>
                </td>
                <!-- Status von TSM1 -->
                <td>
                  {{ pfItem['processingStatus' + this.$getUpLangKey()] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { DTSHeaders } from '@/services/BackendService'

export default defineComponent({
  name: 'SegmentationFormValidationProcessingTable',
  props: {
    items: { type: Array, required: true }
  },
  data() {
    return {
      farmFields: [
        // TSM1
        {
          title: this.$t('step_four_processing_form'),
          key: 'code',
          sortable: false,
          width: '10%',
          align: 'left'
        },

        // Fütterung
        {
          title: this.$t('segmentation_forms_preview_feeding'),
          key: 'feeding',
          sortable: false,
          width: '10%'
        },
        // Placeholder MBH-ID
        {
          title: '',
          key: '',
          sortable: false,
          width: '5%'
        },
        // Placeholder Name/Firma
        {
          title: '',
          key: '',
          sortable: false,
          width: '10%'
        },
        // Placeholder Vorname/Zusatz
        {
          title: '',
          key: '',
          sortable: false,
          width: '10%'
        },
        // Placeholder Strasse
        {
          title: '',
          key: '',
          sortable: false,
          width: '10%'
        },
        // Placeholder PLZ
        {
          title: '',
          key: '',
          sortable: false,
          width: '5%'
        },
        // Placeholder Ort
        {
          title: '',
          key: '',
          sortable: false,
          width: '10%'
        },
        // Menge in kg
        {
          title: this.$t('segmentation_forms_preview_quantity'),
          key: 'quantity',
          sortable: false,
          width: '10%',
          align: 'end'
        },
        // Differenz zu TSM 1
        {
          title: this.$t('segmentation_forms_preview_difference_tsm_1'),
          key: '',
          sortable: false,
          width: '10%',
          align: 'end'
        },
        // Status von TSM 1
        {
          title: this.$t('segmentation_forms_preview_status_tsm_1'),
          key: 'processingStatus' + this.$getUpLangKey(),
          sortable: false,
          width: '10%'
        }
      ] as DTSHeaders
    }
  },
  methods: {
    getClass(pfItem) {
      if (Math.abs(pfItem.sumQuantityABC - pfItem.quantity) > 50) {
        return 'red-difference col-min-width'
      }
      return 'col-min-width'
    }
  },
  mounted: function () {
    this.$nextTick(async () => {})
  }
})
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.red-difference {
  color: $dbm-red !important;
}

.custom-table-styling > .v-table__wrapper .v-input__slot > .v-text-field__slot {
  background: #f1dcb0;
}
</style>
